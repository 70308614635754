import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus";
import Verse from "../../components/Verse.js";

export default function Neon() {
  return (
    <Layout>
      <Helmet title="Neon | John Mayer" />

      <h1>Neon</h1>

      <h2>John Mayer</h2>

      <h4>Key: Cm</h4>

      <h4>Tempo: 108</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>When sky blue gets dark enough</p>
          <p>To see the colors of the city lights</p>
          <p>A trail of ruby red and diamond white</p>
          <p>Hits her like a sunrise</p>
        </Verse>
        <PreChorus>
          <p>She comes and she goes</p>
          <p>Like no one can</p>
        </PreChorus>
        <Verse>
          <p>Tonight she's out to lose herself</p>
          <p>And find a high on Peachtree Street</p>
          <p>From mixed drinks to techno beats it's always</p>
          <p>Heavy into everything</p>
        </Verse>
        <PreChorus>
          <p>She comes and goes and comes and goes</p>
          <p>Like no one can</p>
          <p>She comes and goes and no one knows</p>
          <p>She's slipping through my hands</p>
        </PreChorus>
        <Chorus>
          <p>She's always buzzing just like</p>
          <p>Neon, neon</p>
          <p>Neon, neon</p>
          <p>Who knows how long, how long, how long</p>
          <p>She can go before she burns away</p>
        </Chorus>
        <Verse>
          <p>I can't be her angel now</p>
          <p>You know it's not my place to hold her down</p>
          <p>And it's hard for me to take a stand</p>
          <p>When I would take her anyway I can</p>
        </Verse>
        <PreChorus>
          <p>She comes and goes and comes and goes</p>
          <p>Like no one can</p>
          <p>She comes and goes and no one knows</p>
          <p>She's slipping through my hands</p>
        </PreChorus>
        <Chorus>
          <p>She's always buzzing just like</p>
          <p>Neon, neon</p>
          <p>Neon, neon</p>
          <p>Who knows how long, how long, how long</p>
          <p>She can go before she burns away</p>
          <p>Away</p>
        </Chorus>
        <Bridge>
          <p>CHORDS</p>
        </Bridge>
        <PreChorus>
          <p>She comes and goes and comes and goes</p>
          <p>Like no one can</p>
          <p>She comes and goes and no one knows</p>
          <p>She's slipping through my hands</p>
        </PreChorus>
        <Chorus>
          <p>She's always buzzing just like</p>
          <p>Neon, neon</p>
          <p>Neon, neon</p>
          <p>Who knows how long, how long, how long</p>
          <p>She can go before she burns away</p>
          <p>Away</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
